@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  

}
h1,h2,h3,h4,h5,h6,p {
    white-space: pre-line !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body {overflow-x: hidden !important;width: 100vw !important;}
.flex {
    display: flex !important;
}
.flex-c {
    display: flex !important;
    flex-direction: column;
}
.flex__center-r {
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}
.flex__center-c {
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

@media screen and (max-width: 690px) {
    .flex__r-c {
        flex-direction: column;
    }
}
.header-img-noblur {
    filter: brightness(0.7);    
    top: 0; left: 0; right: 0; bottom: 0;
    position: absolute;
    object-fit: cover;
    width: 100vw;
    height: 100%;
    z-index: -1;
    transform: translate3d(0,0,0);      
}



/** MainLoading icon animation */
.opacity_animation {
    animation: opacity-rotate 3s linear infinite;
}
@keyframes opacity-rotate {
    0% {
        opacity: 1;
        transform: scale3d(1.2,1.2,1.2);
    }
    50% {
        opacity: 0.2;
        transform: scale3d(1,1,1);
    }
    100% {
        opacity: 1;
        transform: scale3d(1.2,1.2,1.2);
    }
}



